import { Box, ImageIcon, Text } from '@deposits/ui-kit-react';
import '../../sass/components/_firstColumnAd.scss';

// fetch next ad creative (image) to show in the first column

const FirstColumnAd = () => {
  return (
    <Box className="firstColumnAd">
        <Box>
          <img
            src="https://storage.googleapis.com/smartai-stockimage-dev-storage-assets-na/creatives/stock-images-skyscraper-ad.png"
            onClick={() => window.open('https://smartai.studio/')}
            alt="ad"
            width="100%" 
            height="98%"
          />
        </Box>
    
      </Box>

  );
};

export default FirstColumnAd;
