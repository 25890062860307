
import HeroItem from './HeroItem';
import SpinnerMini from '../Spinner/SpinnerMini';
import '../../sass/components/_hero.scss';
import { usePosts } from '../../hooks/usePosts';
import { filterFuturePosts } from '../../services/utilsStringFuncs';
import { Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { randomizeByCategory } from '../../services/utilsStringFuncs';
import { Text } from '@deposits/ui-kit-react';

// import Icon from material-ui
const Hero = () => {
  const { isLoading, posts } = usePosts();
  
  if (isLoading) return <SpinnerMini />;
  if (posts.length === 0) return null;

  var filteredPosts = filterFuturePosts(posts, "all");

  var futurePosts = filteredPosts.filter(
    (post) => new Date(post.publisher.publishDate) <= new Date());
  
    var randomizeRecentPosts = randomizeByCategory(futurePosts,5);

  if (futurePosts.length > 0) {
  return (
      <div className="wpo-blog-hero-area">
        <div className="container">
          <Box className="wpo-section-title">
            <h2>Latest News</h2>            
            <Box style={{ 
              // add shadow
              backgroundColor: "var(--color-background)",
              fontSize: "0.9rem", 
              fontWeight: "600", 
              fontStyle: "italic",
              padding: "0.2rem 0",
              color: "var(--color-border)",
            }}>explore, enlighten, enrich
            </Box>
            <Box style={{ 
              fontSize: "0.9rem", 
              fontWeight: "normal", 
              fontStyle: "italic",
              padding: "0.5rem 0",
            }}>SMARTAI.NEWS is a news and blog aggregation portal, check out our <Link to="/faq">FAQ</Link>
            </Box>
            
          </Box>

          <div className="sortable-gallery">
            <div className="gallery-filters"></div>
            <div className="row">
              <div className="col-lg-12">
                <div className="wpo-blog-grids gallery-container clearfix">
                  {randomizeRecentPosts.map((post, index) => (
                    <div
                      className={`grid ${index > 0 ? 'grid--half' : ''}`}
                      key={post.id}
                    >
                      <HeroItem post={post} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  return null;
}

export default Hero;