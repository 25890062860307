import React from 'react';
import { Box, Text } from '@deposits/ui-kit-react';
import GridLayout from './GridLayout';
import SecondColumnAd from '../components/ColumnAd/SecondColumnAd';
import { useLocation } from 'react-router-dom';

const NewsFeedLayout = ({ firstColumnContent, gridItems, firstColumnAd }) => {
  // get current page name and capitalize first letter
  const location = useLocation();
  var page = location.pathname.split('/')[1];

  // capitalize first letter
  page = page.charAt(0).toUpperCase() + page.slice(1);
  //console.log('page', page);
  
  return (

    <div>
      <div className="column__layout">
        <div className="first__column__section p-1">
          {firstColumnContent}
          {firstColumnAd}
        </div>

        <div className="second__column__section p-3">
          <div className="news__feed__nav__section">
            <Box>
              <Text
                className="news__feed__nav__section__font"
                style={{ fontWeight: 'bold' }}
              >Recent Articles for {page} </Text>              
            </Box>
          </div>
          
          <GridLayout>{gridItems}</GridLayout>
        </div>

        <div className="third__column__section p-2">
          <SecondColumnAd />
        </div>
      </div>
    </div>
  );
};

export default NewsFeedLayout;
