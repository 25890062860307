import { Box, Text } from '@deposits/ui-kit-react';
import { useCategory } from './useCategory';
import arrowBackIcon from '../../Icons/arrowBackIcon.svg';
import { useMoveBack } from '../../hooks/useMoveBack';
import { Link } from 'react-router-dom';

const CategoryWidget = () => {
  //const { isLoading, categories } = useCategory();
  const moveBack = useMoveBack();
  //if (isLoading) return null;

  /*
  const filteredCategories = categories.filter((category) => {
    return (
      category === 'Business' || category === 'Science' || category === 'World'
    );
  });

  const filterStuff = (category) => {
    return {filteredCategories.map((category, index) => (
      <Box key={index}>
        <Link to={`/${category.toLowerCase()}`}>
          <Text className="category__text">{category}</Text>
        </Link>
      </Box>
    ))}
  };
  */

  return (
    <Box>
      <div className="nav__section" onClick={moveBack}>
        <Box is="img" src={arrowBackIcon} width="20px" />
        <Box>
          <Text className="nav__main__text__font">Back</Text>
        </Box>
      </div>
      <Box className="category__widget_layout">
        <Box className="main_category_text">
          <Text className="header__category__text"> Categories</Text>
        </Box>
        <Box className="category__text__layout">
          <Link to="/world">
            <Text className="category__text">World</Text>
          </Link>
        </Box>
        <Box className="category__text__layout">
          <Link to="/government">
            <Text className="category__text">U.S. Government</Text>
          </Link>
        </Box>
        <Box className="category__text__layout">
          <Link to="/health">
            <Text className="category__text">Health</Text>
          </Link>
        </Box>
        <Box className="category__text__layout">
          <Link to="/science">
            <Text className="category__text">Science</Text>
          </Link>
        </Box>
        <Box className="category__text__layout">
          <Link to="/environment">
            <Text className="category__text">Environment</Text>
          </Link>
        </Box>
        <Box className="category__text__layout">
          <Link to="/business">
            <Text className="category__text">Business</Text>
          </Link>       
        </Box>
        <Box className="category__text__layout">
          <Link to="/technology">
            <Text className="category__text">Technology</Text>
          </Link>
        </Box>
       
      </Box>
    </Box>
  );
};

export default CategoryWidget;
