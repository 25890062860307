import { Box } from "@deposits/ui-kit-react";
import { useEnvironment } from "../../main-component/EnvironmentBlogPage/useEnvironment";
import getImageUrl from "../NoImage/NoImage";
import SpinnerMini from "../Spinner/SpinnerMini";
import { cleanSummary } from "../../services/utilsStringFuncs";
import { AIGeneratedSection } from "../../services/utilsStringFuncs";
import { generateTopicButton } from "../../services/utilsStringFuncs";
import { generateCategoryButton } from "../../services/utilsStringFuncs";
import { showPostBody } from "../../services/utilsStringFuncs";
import { filterFuturePosts } from "../../services/utilsStringFuncs";

const EnvironmentHeadlineCards = () => {
  const { isLoading, posts, error } = useEnvironment();

  if (isLoading) return <SpinnerMini />;
  if (error) return <div>An error occured</div>;

  var filteredPosts = filterFuturePosts(posts, "Environment");

  return (
    <>
      {filteredPosts.map((post, index) => (
        <Box className="headline__layout" key={index}>
          <Box className="img-holder" title={cleanSummary(post)}>
            <img
              className="img img-responsive"
              src={getImageUrl(post.imageUrl)}
              alt="Post"
              width="100%"
              onClick={() => window.open(post.url, "_blank")}
            />
            <div style={{ padding: "1rem" }}>
              {generateCategoryButton(post.category)}
              {generateTopicButton(post.topics[0], 0)}
            </div>
          </Box>
          <Box className="content__section">{showPostBody(post)}</Box>
          {AIGeneratedSection(post)}
        </Box>
      ))}
    </>
  );
};

export default EnvironmentHeadlineCards;
