import { Box } from "@deposits/ui-kit-react";
import { useBusiness } from "../../main-component/FinanceBlogPage/useBusiness";
import getImageUrl from "../NoImage/NoImage";
import SpinnerMini from "../Spinner/SpinnerMini";
import { cleanSummary } from "../../services/utilsStringFuncs";
import { AIGeneratedSection } from "../../services/utilsStringFuncs";
import { generateTopicButton } from "../../services/utilsStringFuncs";
import { generateCategoryButton } from "../../services/utilsStringFuncs";
import { filterFuturePosts } from "../../services/utilsStringFuncs";
import { showPostBody } from "../../services/utilsStringFuncs";

const BusinessHeadlineCards = () => {
  const { isLoading, posts, error } = useBusiness();

  if (isLoading) return <SpinnerMini />;
  if (error) return <div>An error occured</div>;

  var filteredPosts = filterFuturePosts(posts, "Business");

 
    return (
      <>
        {filteredPosts.map((post, index) => (
          <Box className="headline__layout" key={index}>
            <Box className="img-holder" title={cleanSummary(post)}>
              <img
                className="img img-responsive"
                src={getImageUrl(post.imageUrl)}
                alt="Post"
                width="100%"
                onClick={() => window.open(post.url, "_blank")}
              />
              <div style={{ padding: "1rem" }}>
                {generateCategoryButton(post.category)}
                {generateTopicButton(post.topics[0], 0)}
              </div>
            </Box>
            <Box className="content__section">{showPostBody(post)}</Box>
            {AIGeneratedSection(post)}
          </Box>
        ))}
      </>
    );
  }

export default BusinessHeadlineCards;
