import { Box, Text, TextField, SearchIcon } from '@deposits/ui-kit-react';
import { Link, useNavigate } from 'react-router-dom';
import profileIcon from '../../Icons/profileIcon.svg';
import LogoComponent from './LogoComponent';
import greenLogoIcon from '../../Icons/greenLogoIcon.svg';
import purpleLogoIcon from '../../Icons/purpleLogoIcon.svg';
import { Menu } from '@mantine/core';
import { useRef } from 'react';
import usePostQueryStore from '../../store';
import '../../sass/components/_blog-header.scss';
import '../../sass/components/_mobileMenu.scss';
import MobileMenu from '../MobileMenu/MobileMenu';
import { useColorMode } from '../../context/ColorModeContext';

const BlogHeader = (props) => {
  const ref = useRef(null);
  const setSearchText = usePostQueryStore((s) => s.setSearchText);
  const navigate = useNavigate();
  const { colorMode, colorModes } = useColorMode();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const searchTextValue = ref.current.value;
    setSearchText(searchTextValue);
    navigate('/search');
  };

  let userProfile = () => {
    <Box className="navbar__profile__section">
    <Box className="nav__bar__profile">
      <Box is="a" href="#">
        <Box is="img" src={profileIcon} width="30px" />
      </Box>
      <Box>
        <Text>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Box className="header__text__font">Profile</Box>
            </Menu.Target>

            <Menu.Dropdown className="header__menu">
              <Link to="/profile">
                <Menu.Item className="header__menu__drop">
                  My Profile
                </Menu.Item>
              </Link>
              <Link to="/bookmarks">
                <Menu.Item className="header__menu__drop">
                  <Link to="/bookmarks">Bookmarks</Link>
                </Menu.Item>
              </Link>
              <Link to="/settings">
                <Menu.Item className="header__menu__drop">
                  <Link to="/settings">Settings</Link>
                </Menu.Item>
              </Link>
            </Menu.Dropdown>
          </Menu>
        </Text>
      </Box>
    </Box>
  </Box>
  }
  
  return (
    <Box is="header" id="navbar">
      <Box className="navbar__section navbar__section--headerV2">
        <Box is={Link} className="logo" to="/">
          <LogoComponent
            src={`${
              colorMode === colorModes.green ? greenLogoIcon : purpleLogoIcon
            }`}
            width="80%"
          />
        </Box>
        <form className="navbar__section__form" onSubmit={handleFormSubmit}>
          <TextField
            ref={ref}
            size="medium"
            leftIcon={SearchIcon}
            placeholder="Search news"
          />
        </form>
        
       

        <div className={`wpo-site-header ${props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="mobail-menu menuToggle">
              <div className="mobail-menu">
                <MobileMenu />
              </div>
            </div>
          </nav>
        </div>
      </Box>
    </Box>
  );
};

export default BlogHeader;
