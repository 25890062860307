import { Box, Text } from '@deposits/ui-kit-react';
import '../../sass/components/_secondColumnAd.scss';

const SecondColumnAd = () => {
  return (
    <Box />
  );
};

export default SecondColumnAd;
