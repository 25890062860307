const API_URL = process.env.REACT_APP_API_URL
//"https://smartai-news-api-dev-us-east1-vjq6bs7ara-ue.a.run.app" 
//"https://localhost:49153";

export async function getBusinessPosts() {
  const res = await fetch(`${API_URL}/posts/category/Business`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}

export async function getWorldPosts() {
  const res = await fetch(`${API_URL}/posts/category/World`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}

export async function getSciencePosts() {
  const res = await fetch(`${API_URL}/posts/category/Science`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}

export async function getHealthPosts() {
  const res = await fetch(`${API_URL}/posts/category/Health`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}

export async function getGovernmentPosts() {
  const res = await fetch(`${API_URL}/posts/category/Government`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}

export async function getEnvironmentPosts() {
  const res = await fetch(`${API_URL}/posts/category/Environment`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}
export async function getTechnologyPosts() {
  const res = await fetch(`${API_URL}/posts/category/Technology`);

  if (!res.ok) throw Error('Failed getting posts');

  const data = await res.json();
  return data;
}
export async function getCategory() {
  const res = await fetch(`${API_URL}/category`);

  if (!res.ok) throw Error('Failed getting categories');

  const data = await res.json();
  return data;
}
