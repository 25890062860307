import React, { Fragment } from 'react';
import BlogHeader from '../../components/FeedHeader/BlogHeader';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import NewsFeedLayout from '../../layouts/NewsFeedLayout';
import CategoryWidget from '../../components/CategoriesWidget/CategoryWidget';
import BusinessHeadlineCards from '../../components/HeadlineCard/BusinessHeadlineCards';
import FirstColumnAd from '../../components/ColumnAd/FirstColumnAd';
import { useBusiness } from './useBusiness';
import SpinnerMini from '../../components/Spinner/SpinnerMini';

const FinanceBlogPage = () => {
  const headlineCards = <BusinessHeadlineCards />;

  const { isLoading } = useBusiness();

  if (isLoading)
    return (
      <Fragment>
        <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
        <SpinnerMini />
      </Fragment>
    );

  return (
    <Fragment>
      <BlogHeader hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} />
      <NewsFeedLayout
        firstColumnContent={<CategoryWidget />}
        firstColumnAd={<FirstColumnAd />}
        gridItems={headlineCards}
      />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default FinanceBlogPage;
